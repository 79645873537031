import { Content } from '@vccp/bernadette-components';
import { graphql } from 'gatsby';
import React from 'react';
import HomeClientGrid from '../components/HomeClientGrid/HomeClientGrid';
import HomeHeroAnimation from '../components/HomeHeroAnimation/HomeHeroAnimation';
import HomepageProjectSpotlight from '../components/HomepageProjectSpotlight/HomepageProjectSpotlight';
import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo/Seo';
import ContactUs from '../components/ContactUs/ContactUs';
import PageTransition from 'gatsby-plugin-page-transitions';
import * as styles from './index.module.scss';
import useComponentFactory from '../hooks/useComponentFactory';

const IndexPage = ({ data, location }) => {
  const pageData = data.allContentfulHome.nodes[0];
  const components = useComponentFactory(pageData.components.slice(0, 1)); // at the moment if I pull all the other components broke (need to explore more) but with .slice(0, 1) I will get only the first component

  return (
    <>
      <Seo
        contentTitle={pageData?.title}
        pathname={location?.pathname}
        seo={pageData?.seo}
      />
      <PageTransition>
        <Layout
          {...(pageData?.pageThemeColour && {
            style: { '--theme-accent-color': `#${pageData?.pageThemeColour}` },
          })}>
          <div style={{ position: 'relative' }}>
            <h1 className={styles.title}>Bernadette</h1>
            {typeof window !== 'undefined' && (
              <HomeHeroAnimation
                positioningStatementLinkText={
                  pageData.positioningStatementLinkText
                }
                positioningStatementLink={
                  pageData.positioningStatementLink?.slug
                }
                positioningStatement={pageData.positioningStatement}
              />
            )}
          </div>
          <Content>
            {/* banner */}
            {components}
            {/* <ContentfulComponentTwoColumnImageAndDescription title="hello" /> */}
            <HomepageProjectSpotlight title="OUR WORK" />
            <HomeClientGrid />
            <ContactUs />
          </Content>
        </Layout>
      </PageTransition>
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query HomePageQuery {
    allContentfulHome(filter: { title: { ne: "__DUMMY_ITEM__" } }) {
      nodes {
        title
        seo {
          canonicalUrl
          description
          seoTitle
          image {
            file {
              url
            }
            title
            width
            height
          }
        }
        positioningStatement {
          raw
        }
        positioningStatementLinkText
        positioningStatementLink {
          ... on ContentfulContact {
            slug
          }
          ... on ContentfulPages {
            slug
          }
        }
        components {
          __typename
          ... on ContentfulComponentTwoColumnImageAndDescription {
            ...ContentfulComponentTwoColumnImageAndDescriptionFields
          }
        }
      }
    }
  }
`;
